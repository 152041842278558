import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./translation/en/translation.json";
import translationFR from "./translation/fr/translation.json";
import i18next from "i18next";

const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
}

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: "fr",
    });

export default i18n;