import React, { useState } from 'react';
import '../styles/components/carousel.css';
import {useTranslation} from "react-i18next";

import profilPicture1 from "../assets/Home/profil-picture1.webp";
import profilPicture2 from "../assets/Home/profil-picture2.webp";
import profilPicture3 from "../assets/Home/profil-picture3.webp";

function Carousel() {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(1);

    const item1__content = (
        <div className="homeReviewsContainer__card">
            <div className="homeReviewsContainerCard__picture">
                <img src={profilPicture1} alt=""/>
            </div>
            <div className="homeReviewsContainerCard__text">
                <p className="homeReviewsContainerCardText__review">“{t('home.reviews.review1.content')}”</p>
                <p className="homeReviewsContainerCardText__name">{t('home.reviews.review1.name')}</p>
            </div>
        </div>
    );

    const item2__content = (
        <div className="homeReviewsContainer__card">
            <div className="homeReviewsContainerCard__picture">
                <img src={profilPicture2} alt=""/>
            </div>
            <div className="homeReviewsContainerCard__text">
                <p className="homeReviewsContainerCardText__review">“{t('home.reviews.review2.content')}”</p>
                <p className="homeReviewsContainerCardText__name">{t('home.reviews.review2.name')}</p>
            </div>
        </div>
    );

    const item3__content = (
        <div className="homeReviewsContainer__card">
            <div className="homeReviewsContainerCard__picture">
                <img src={profilPicture3} alt=""/>
            </div>
            <div className="homeReviewsContainerCard__text">
                <p className="homeReviewsContainerCardText__review">“{t('home.reviews.review3.content')}”</p>
                <p className="homeReviewsContainerCardText__name">{t('home.reviews.review3.name')}</p>
            </div>
        </div>
    );

    const items = [
        { id: 1, content: item1__content },
        { id: 2, content: item2__content },
        { id: 3, content: item3__content }
    ];

/*    const handleMove = (dir: string) => {
        let newIndex;

        if (dir === 'prev') {
            newIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        } else {
            newIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        }

        setActiveIndex(newIndex);
    }*/

    return (
        <>
            <div className="carousel">
                <div className="carousel__main">
                        {items.map((item, index) => (
                            <div
                                key={item.id}
                                className={`carouselMain__item ${index === activeIndex ? 'item--active' : index === activeIndex - 1 || (activeIndex === 0 && index === items.length - 1) ? 'item--left' : 'item--right'}`}
                            >
                                {item.content}
                            </div>
                        ))}
                </div>
            </div>
            <div className="pagination">
                {items.map((item, index) => (
                    <div
                        key={item.id}
                        className={`index ${index === activeIndex ? 'index--active' : ''}`}
                        onClick={() => setActiveIndex(index)}
                    />
                ))}
            </div>
        </>
    )
}

export default Carousel;