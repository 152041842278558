import React, {useEffect, useState} from 'react';
import '../styles/pages/home.css';
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Carousel from "../components/Carousel";

import homepageBackground from '../assets/Home/homepage-background-image.webp';

import card1 from '../assets/Home/card1.webp';
import card2 from '../assets/Home/card2.webp';
import card3 from '../assets/Home/card3.webp';
import card4 from '../assets/Home/card4.webp';
import card5 from '../assets/Home/card5.webp';
import card6 from '../assets/Home/card6.webp';

import flowerIcon from '../assets/Home/svg/flower-icon.svg';
import grassIcon from '../assets/Home/svg/grass-icon.svg';
import nurseryIcon from '../assets/Home/svg/nursery-icon.svg';
import bushIcon from '../assets/Home/svg/bush-icon.svg';
import mowerIcon from '../assets/Home/svg/mower-icon.svg';

import descriptionImage from '../assets/Home/description-image.webp';
import darkLogo from '../assets/Home/logo-inversed-colors.webp';

import profilPicture1 from '../assets/Home/profil-picture1.webp'
import profilPicture2 from '../assets/Home/profil-picture2.webp';
import profilPicture3 from '../assets/Home/profil-picture3.webp';

function Home() {
    const { t } = useTranslation();
    const { state } = useLocation();
    const { targetId } = state || {};
    const [showCarousel, setShowCarousel] = useState(window.innerWidth <= 1300);

    useEffect(() => {
        const el = document.getElementById(targetId);
        if (el) {
            el.scrollIntoView();
        }
    }, [targetId]);

    function handleScrollTo(dir: string) {
        document.querySelector(`#${dir}`)?.scrollIntoView({ behavior: "smooth" });
    }

    const handleResize = () => {
        setShowCarousel(window.innerWidth <= 1300);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <div className="home">
            <div className="home__topFade"></div>
            <section className="home__presentation">
                <div className="homePresentation__content">
                    <div className="homePresentationContent__background">
                        <img src={homepageBackground} alt=""/>
                    </div>
                    <div className="homePresentationContent__text">
                        <h1 className="homePresentationContentText__title">{t('home.presentation.title')}</h1>
                        <p className="homePresentationContentText__subtitle">{t('home.presentation.subtitle')}</p>
                        <div className="homePresentationContentText__button">
                            <div onClick={() => handleScrollTo('step1')} >{t('home.presentation.button')}</div>
                        </div>
                    </div>
                    <div id="step1" className="homePresentationContent__container">
                        <div className="homePresentationContent__cards">
                            <div className="homePresentationContentCards__card">
                                <img className="homePresentationContentCardsCard__background" src={card1} alt=""/>
                                <div className="homePresentationContentCardsCard__hover">
                                    <p className="homePresentationContentCardsCardHover__title">{t('home.presentation.cards.card1.title')}</p>
                                    <p className="homePresentationContentCardsCardHover__subtitle">{t('home.presentation.cards.card1.subtitle')}</p>
                                    <div className="homePresentationContentCardsCardHover__date">2023</div>
                                </div>
                                <div className="homePresentationContentCardsCard__border"></div>
                            </div>
                            <div className="homePresentationContentCards__card">
                                <img className="homePresentationContentCardsCard__background" src={card2} alt=""/>
                                <div className="homePresentationContentCardsCard__hover">
                                    <p className="homePresentationContentCardsCardHover__title">{t('home.presentation.cards.card2.title')}</p>
                                    <p className="homePresentationContentCardsCardHover__subtitle">{t('home.presentation.cards.card2.subtitle')}</p>
                                    <div className="homePresentationContentCardsCardHover__date">2020</div>
                                </div>
                                <div className="homePresentationContentCardsCard__border"></div>
                            </div>
                            <div className="homePresentationContentCards__card">
                                <img className="homePresentationContentCardsCard__background" src={card3} alt=""/>
                                <div className="homePresentationContentCardsCard__hover">
                                    <p className="homePresentationContentCardsCardHover__title">{t('home.presentation.cards.card3.title')}</p>
                                    <p className="homePresentationContentCardsCardHover__subtitle">{t('home.presentation.cards.card3.subtitle')}</p>
                                    <div className="homePresentationContentCardsCardHover__date">2023</div>
                                </div>
                                <div className="homePresentationContentCardsCard__border"></div>
                            </div>
                            <div className="homePresentationContentCards__card">
                                <img className="homePresentationContentCardsCard__background" src={card4} alt=""/>
                                <div className="homePresentationContentCardsCard__hover">
                                    <p className="homePresentationContentCardsCardHover__title">{t('home.presentation.cards.card4.title')}</p>
                                    <p className="homePresentationContentCardsCardHover__subtitle">{t('home.presentation.cards.card4.subtitle')}</p>
                                    <div className="homePresentationContentCardsCardHover__date">2019</div>
                                </div>
                                <div className="homePresentationContentCardsCard__border"></div>
                            </div>
                            <div className="homePresentationContentCards__card">
                                <img className="homePresentationContentCardsCard__background" src={card5} alt=""/>
                                <div className="homePresentationContentCardsCard__hover">
                                    <p className="homePresentationContentCardsCardHover__title">{t('home.presentation.cards.card5.title')}</p>
                                    <p className="homePresentationContentCardsCardHover__subtitle">{t('home.presentation.cards.card5.subtitle')}</p>
                                    <div className="homePresentationContentCardsCardHover__date">2021</div>
                                </div>
                                <div className="homePresentationContentCardsCard__border"></div>
                            </div>
                            <div className="homePresentationContentCards__card">
                                <img className="homePresentationContentCardsCard__background" src={card6} alt=""/>
                                <div className="homePresentationContentCardsCard__hover">
                                    <p className="homePresentationContentCardsCardHover__title">{t('home.presentation.cards.card6.title')}</p>
                                    <p className="homePresentationContentCardsCardHover__subtitle">{t('home.presentation.cards.card6.subtitle')}</p>
                                    <div className="homePresentationContentCardsCardHover__date">2020</div>
                                </div>
                                <div className="homePresentationContentCardsCard__border"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="step2" className="home__services">
                <div className="homeServices__header">{t('home.services.title')}</div>
                <div className="homeServices__content">
                    <div className="homeServicesContent__cards">
                        <div className="homeServicesContentCards__card">
                            <div className="homeServicesContentCardsCard__icon">
                                <img src={flowerIcon} alt=""/>
                            </div>
                            <p className="homeServicesContentCardsCard__title">{t('home.services.cards.card1.title')}</p>
                            <p className="homeServicesContentCardsCard__subtitle">{t('home.services.cards.card1.subtitle')}</p>
                        </div>
                        <div className="homeServicesContentCards__card">
                            <div className="homeServicesContentCardsCard__icon">
                                <img src={grassIcon} alt=""/>
                            </div>
                            <p className="homeServicesContentCardsCard__title">{t('home.services.cards.card2.title')}</p>
                            <p className="homeServicesContentCardsCard__subtitle">{t('home.services.cards.card2.subtitle')}</p>
                        </div>
                        <div className="homeServicesContentCards__card">
                            <div className="homeServicesContentCardsCard__icon">
                                <img src={nurseryIcon} alt=""/>
                            </div>
                            <p className="homeServicesContentCardsCard__title">{t('home.services.cards.card3.title')}</p>
                            <p className="homeServicesContentCardsCard__subtitle">{t('home.services.cards.card3.subtitle')}</p>
                        </div>
                        <div className="homeServicesContentCards__card">
                            <div className="homeServicesContentCardsCard__icon">
                                <img src={bushIcon} alt=""/>
                            </div>
                            <p className="homeServicesContentCardsCard__title">{t('home.services.cards.card4.title')}</p>
                            <p className="homeServicesContentCardsCard__subtitle">{t('home.services.cards.card4.subtitle')}</p>
                        </div>
                        <div className="homeServicesContentCards__card">
                            <div className="homeServicesContentCardsCard__icon">
                                <img src={mowerIcon} alt=""/>
                            </div>
                            <p className="homeServicesContentCardsCard__title">{t('home.services.cards.card5.title')}</p>
                            <p className="homeServicesContentCardsCard__subtitle">{t('home.services.cards.card5.subtitle')}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home__description">
                <div className="homeDescription__left">
                    <img src={descriptionImage} alt=""/>
                </div>
                <div className="homeDescription__right">
                    <div className="homeDescriptionRight__logo">
                        <img src={darkLogo} alt=""/>
                    </div>
                    <div className="homeDescriptionRight__text">
                        <p className="homeDescriptionRightText__title">{t('home.description.title')}</p>
                        <p className="homeDescriptionRightText__paragraph">{t('home.description.description')}</p>
                        <div className="homeDescriptionRight__button"><Link to="/contact">{t('home.description.button')}</Link></div>
                    </div>
                </div>
            </section>
            <section className="home__reviews">
                { showCarousel ? (
                    <Carousel />
                ) : (
                <div className="homeReviews__container">
                    <div className="homeReviewsContainer__card">
                        <div className="homeReviewsContainerCard__picture">
                            <img src={profilPicture1} alt=""/>
                        </div>
                        <div className="homeReviewsContainerCard__text">
                            <p className="homeReviewsContainerCardText__review">“{t('home.reviews.review1.content')}”</p>
                            <p className="homeReviewsContainerCardText__name">{t('home.reviews.review1.name')}</p>
                        </div>
                    </div>
                    <div className="homeReviewsContainer__card">
                        <div className="homeReviewsContainerCard__picture">
                            <img src={profilPicture2} alt=""/>
                        </div>
                        <div className="homeReviewsContainerCard__text">
                            <p className="homeReviewsContainerCardText__review">“{t('home.reviews.review2.content')}”</p>
                            <p className="homeReviewsContainerCardText__name">{t('home.reviews.review2.name')}</p>
                        </div>
                    </div>
                    <div className="homeReviewsContainer__card">
                        <div className="homeReviewsContainerCard__picture">
                            <img src={profilPicture3} alt=""/>
                        </div>
                        <div className="homeReviewsContainerCard__text">
                            <p className="homeReviewsContainerCardText__review">“{t('home.reviews.review3.content')}”</p>
                            <p className="homeReviewsContainerCardText__name">{t('home.reviews.review3.name')}</p>
                        </div>
                    </div>
                </div>
                )}
            </section>
        </div>
    );
}
export default Home;