import React, {useEffect} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import i18n from "../i18n";

import '../styles/components/navbar.css';

import logo from '../assets/Navbar/logo.png';
import darkLogo from '../assets/Navbar/dark-logo.webp';

import facebookIcon from '../assets/Navbar/svg/facebook-icon.svg';
import mailIcon from '../assets/Navbar/svg/mail-icon.svg';
import facebookGreenIcon from '../assets/Navbar/svg/facebook-green-icon.svg';
import mailGreenIcon from '../assets/Navbar/svg/mail-green-icon.svg';

function Navbar() {
    const location = useLocation();
    const navigate = useNavigate();

    // switch language
    function handleLanguageChange(lang: string) {
        i18n.changeLanguage(lang);

        const buttonFR = document.querySelectorAll("#buttonFR");
        const buttonEN = document.querySelectorAll("#buttonEN");

        if (lang === "fr") {
            for (let i = 0; i < buttonFR.length; i++) {
                buttonEN[i]?.classList.remove("active");
                buttonFR[i]?.classList.add("active");
            }
        } else {
            for (let i = 0; i < buttonEN.length; i++) {
                buttonFR[i]?.classList.remove("active");
                buttonEN[i]?.classList.add("active");
            }
        }
    }

    useEffect(() => {
        if (window.location.pathname === "/contact") {
            // change navbar colors
            document.querySelector(".navbar__desktop")?.classList.add("navbar--contact");

            // change logo
            document.querySelector(".navbarDesktopMain__logo")?.setAttribute("src", darkLogo);

            //change logo for mobile version
            document.querySelector(".navbarMobile__logo img")?.setAttribute("src", darkLogo);

            //change icons colors
            document.querySelector(".navbarDesktopSocials__facebook-icon")?.setAttribute("src", facebookGreenIcon);
            document.querySelector(".navbarDesktopSocials__mail-icon")?.setAttribute("src", mailGreenIcon);
        } else {
            document.querySelector(".navbar__desktop")?.classList.remove("navbar--contact");
            document.querySelector(".navbarDesktopMain__logo")?.setAttribute("src", logo);
            document.querySelector(".navbarMobile__logo img")?.setAttribute("src", logo);
            document.querySelector(".navbarDesktopSocials__facebook-icon")?.setAttribute("src", facebookIcon);
            document.querySelector(".navbarDesktopSocials__mail-icon")?.setAttribute("src", mailIcon);
        }
    }, [location]);

    function handleScrollTo(dir: string) {
        if (window.location.pathname !== "/") {
            navigate("/", { state: { targetId: `${dir}` } });
        }
        document.querySelector(`#${dir}`)?.scrollIntoView({ behavior: "smooth" });
    }

    return (
    <>
        <div className="navbar">
            <div className="navbar__desktop">
                <div className="navbarDesktop__languages">
                    <div id="buttonFR" className="navbarDesktopLanguages__language active" onClick={() => {handleLanguageChange('fr')}}>FR</div>
                    <div className="navbarDesktopLanguages__separator"> / </div>
                    <div id="buttonEN" className="navbarDesktopLanguages__language" onClick={() => {handleLanguageChange('en')}}>EN</div>
                </div>
                <div className="navbarDesktop__main">
                    <ul className="navbarDesktopMain__left">
                        <li className="navbarDesktopMain__link"><Link to="/">Accueil</Link></li>
                        <li className="navbarDesktopMain__link" onClick={() => handleScrollTo('step1')}>Réalisations</li>
                    </ul>
                    <img src={logo} className="navbarDesktopMain__logo"  alt="logo"/>
                    <ul className="navbarDesktopMain__right">
                        <li className="navbarDesktopMain__link" onClick={() => handleScrollTo('step2')}>Services</li>
                        <li className="navbarDesktopMain__link"><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
                <ul className="navbarDesktop__socials">
                    {/*<li><img className="navbarDesktopSocials__facebook-icon" src={facebookIcon} alt=""/></li>*/}
                    {/*<li><a href="mailto:herve@lesjardins-herve.fr"><img className="navbarDesktopSocials__mail-icon" src={mailIcon} alt=""/></a></li>*/}
                </ul>
            </div>
            <div className="navbar__mobile">
                <div className="navbarMobile__logo">
                    <img src={logo} alt="logo"/>
                </div>
                <div className="navbarMobile__toggle">
                    <input type="checkbox" />
                    <span></span>
                    <span></span>
                    <span></span>
                    <ul className="navbarMobileToggle__menu">
                        <li><Link to="/">Accueil</Link></li>
                        <li onClick={() => handleScrollTo('step1')}>Réalisations</li>
                        <li onClick={() => handleScrollTo('step2')}>Services</li>
                        <li><Link to="/contact">Contact</Link></li>
                        <div className="navbarMobileToggleMenu__languages">
                            <div id="buttonFR" className="navbarMobileToggleMenuLanguages__language active" onClick={() => {handleLanguageChange('fr')}}>FR</div>
                            <div className="navbarMobileToggleMenuLanguages__separator"> / </div>
                            <div id="buttonEN" className="navbarMobileToggleMenuLanguages__language" onClick={() => {handleLanguageChange('en')}}>EN</div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </>
    );
}
export default Navbar;