import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import '../styles/components/footer.css';

function Navbar() {
    const navigate = useNavigate();

    function handleScrollTo(dir: string) {
        if (window.location.pathname !== "/") {
            navigate("/", { state: { targetId: `${dir}` } });
        }
        document.querySelector(`#${dir}`)?.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <>
            <div className="footer">
                <div className="footer__legal">Copyright © 2023 réalisé par Docaret.</div>
                <ul className="footer__links">
                    <li className="footerLinks__link"><Link to="/">Accueil</Link></li>
                    <li className="footerLinks__link" onClick={() => handleScrollTo('step1')}>Réalisations</li>
                    <li className="footerLinks__link" onClick={() => handleScrollTo('step2')}>Services</li>
                    <li className="footerLinks__link"><Link to="/contact">Contact</Link></li>
                </ul>
            </div>
        </>
    );
}
export default Navbar;