import React from 'react';
import { Route, Routes } from "react-router-dom";
import './styles/global.css';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';

function App() {
  return (
    <div className="app">
        <Navbar />
        <div className="app__content">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </div>
        <Footer />
    </div>
  );
}

export default App;
