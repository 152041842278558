import React from 'react';
import '../styles/pages/contact.css';
import ReCaptcha from "react-google-recaptcha";
import {useTranslation} from "react-i18next";

import contactImage from '../assets/Contact/contact-image.webp';

function Contact() {
    const { t } = useTranslation();

    return (
        <div className="contact">
            <h1 className="contact__title">{t('contact.title')}</h1>
            <div className="contact__main">
                <div className="contactMain__left">
                    <img src={contactImage} alt=""/>
                </div>
                <div className="contactMain__right">
                    <form action="./contact.php" method="POST" className="contactMainRight__form">
                        <input type="text" name="firstname" placeholder={t('contact.firstname')} required={true}/>
                        <input type="text" name="lastname" placeholder={t('contact.lastname')} required={true}/>
                        <input type="email" name="email" placeholder={t('contact.email')} required={true}/>
                        <input type="tel" name="phone" placeholder={t('contact.phone')} required={true} pattern={"[0-9]{10}"}/>
                        <textarea name="message" id="" cols={30} rows={10} placeholder={t('contact.message')}></textarea>
                        <ReCaptcha
                            sitekey="6LeATdMnAAAAAPJC8S2Nu8Gm-SQnDmQBOzwnBNtO"
                        />
                        <button type="submit">{t('contact.submit')}</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default Contact;